<template>
  <div class="">
    <el-dialog title="权益标签"
               :close-on-click-modal='false'
               :modal-append-to-body="false"
               :visible.sync="dialogVisible"
               width="50%">
      <div class="content">
        <el-tag type="success"
                class="mr-15"
                :key="index"
                v-for="(item,index) in list">{{item.name}}</el-tag>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      list: [],
      loadding: false
    }
  },
  components: {
  },
  methods: {
    showTabList (scope) {
      this.dialogVisible = true
      this.list = []
      this.list = scope.row.label_details
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
